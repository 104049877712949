import { SortOnDigits, SortOnText, TransformRangePickerResult, formTagsNone, formTagsOptionals, notifyError, notifySuccess } from "../../handlers/extensions";
import { Currency, Defaults, QueryCase, QueryId, ToastId } from "../../handlers/enums";
import { Button, DatePicker, Form, Input, InputNumber, Popover, QRCode, Slider, Space, Spin, Switch, Tag, Tooltip } from "antd";
import ComponentState from "../components/states";
import { useTranslation } from "react-i18next";
import { ComponentFormInput, ComponentFormTextarea } from "../components/forms/input";
import { QuestionCircleOutlined, CheckOutlined, CloseOutlined, EditOutlined, SearchOutlined, DeleteOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { GetData, MutateData } from "../../handlers/axios";
import { HolderOutlined, PlusOutlined } from "@ant-design/icons";
import { useAtom } from "jotai";
import { BusinessEventProgress, BusinessId, EventId, UserId } from "../../handlers/globals";
import dayjs from "dayjs";
import moment from "moment";
import SortableList, { SortableItem } from "react-easy-sort";
import { arrayMoveImmutable } from "array-move";
import Paragraph from "antd/es/typography/Paragraph";

export function SectionBusinessEventTickets(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);
    const { t } = useTranslation();

    const responseGeneral = GetData(QueryId.SelectBusinessEventGeneral, { BusinessId: businessId, UserId: userId, EventId: eventId });

    const response = GetData(QueryId.SelectBusinessEventTickets, { BusinessId: businessId, UserId: userId, EventId: eventId });

    const [freezeActions, setFreezeActions] = useState(false);

    const [ticketOverview, setTicketOverview] = useState(true);

    const [giveawayView, setGiveawayView] = useState(false);

    const [ticketEditId, setTicketEditId] = useState(null);

    const [tickets, setTickets] = useState([]);

    const formAction = MutateData(QueryId.UpdateBusinessEventTicketsOrder,
        () => { setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError); } }
    ]);

    const onSortEnd = (oldIndex, newIndex) => {
        setTickets(arrayMoveImmutable(tickets, oldIndex, newIndex));

        setFreezeActions(true);
        formAction.mutate({ form_field_userid: userId, form_field_eventid: eventId, form_field_businessid: businessId, form_field_tickets: arrayMoveImmutable(tickets, oldIndex, newIndex).map((item, index) => { return { form_field_sortindex: index + 1, form_field_id: item.id } }) });
    }

    useEffect(() => { setTickets(response?.data ? response.data.sort((a, b) => { return SortOnDigits(a.sortIndex, b.sortIndex); }) : []); }, [response?.data]);

    return (response.isLoading || responseGeneral.isLoading ? <Spin /> : response.isFalse || responseGeneral.isFalse ? <ComponentState error /> : response.isEmpty
        ? ticketOverview ?
            <div className="flex mb-2 select-none border-sonar-blue border-[1px] bg-sonar-blue hover:bg-sonar-white hover:text-sonar-blue cursor-pointer max-w-fit rounded-md" onClick={() => { setTicketOverview(false); setGiveawayView(false); setTicketEditId(null); }}>
                <PlusOutlined className="ml-2 text-xl" />
                <div className="flex m-2 font-semibold">
                    <p className="my-auto mr-3">{t("form_add_ticket_type")}</p>
                </div>
            </div> : <>
                <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { setTicketOverview(true); setGiveawayView(false); setTicketEditId(null); }} className="bg-sonar-blue mb-5">{t("back")}</Button>
                <NewTicketType fee={responseGeneral.data.fee} currency={responseGeneral.data.currency} setFreezeActions={setFreezeActions} freezeActions={freezeActions} setTicketOverview={setTicketOverview} refetch={response.refetch} />
            </>
        : ticketOverview ? <>
            {/* <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { setTicketOverview(false); setGiveawayView(true); setTicketEditId(null); }} className="bg-sonar-blue mb-5 block">{t("form_giveaway_codes")}</Button> */}
            <Tooltip className="flex w-min" title={t("tooltip_dragdrop")}><p className="font-semibold text-lg mb-2 whitespace-nowrap">{t("sequence_tickets")}</p><QuestionCircleOutlined className="mt-[6px] h-full ml-1 text-lg" /></Tooltip>
            <div className="flex mb-2 select-none border-sonar-blue border-[1px] bg-sonar-blue hover:bg-sonar-white hover:text-sonar-blue cursor-pointer max-w-fit rounded-md" onClick={() => { setTicketOverview(false); setGiveawayView(false); setTicketEditId(null); }}>
                <PlusOutlined className="ml-2 text-xl" />
                <div className="flex m-2 font-semibold">
                    <p className="my-auto mr-3">{t("form_add_ticket_type")}</p>
                </div>
            </div>
            <SortableList onSortEnd={onSortEnd} className="card-p-sm" draggedItemClassName="card-p-sm cursor-grabbing">
                {tickets.map((ticket, index) => {
                    return <div key={ticket.id}>
                        <SortableItem>
                            <div className="flex mb-2 select-none cursor-grab bg-slate-800 hover:bg-slate-700 max-w-fit rounded-md">
                                <HolderOutlined className="mx-1 text-white text-xl" />
                                <Ticket index={index + 1} freezeActions={freezeActions} setFreezeActions={setFreezeActions} refetch={response.refetch} ticket={ticket} setGiveawayView={setGiveawayView} setTicketEditId={setTicketEditId} setTicketOverview={setTicketOverview} />
                            </div>
                        </SortableItem>
                    </div>
                })}
            </SortableList>
        </> : <>
            <Button disabled={freezeActions} loading={freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { setTicketOverview(true); setGiveawayView(false); setTicketEditId(null); }} className="bg-sonar-blue mb-5">{t("back")}</Button>
            {ticketEditId
                ? <EditTicketType fee={responseGeneral.data.fee} currency={responseGeneral.data.currency} setFreezeActions={setFreezeActions} freezeActions={freezeActions} setTicketOverview={setTicketOverview} refetch={response.refetch} ticketEditId={ticketEditId} tickets={tickets} />
                : giveawayView
                    ? <GiveawayTicketType setFreezeActions={setFreezeActions} freezeActions={freezeActions} />
                    : <NewTicketType fee={responseGeneral.data.fee} currency={responseGeneral.data.currency} setFreezeActions={setFreezeActions} freezeActions={freezeActions} setTicketOverview={setTicketOverview} refetch={response.refetch} />
            }
        </>);
}

function Ticket(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);
    const [businessEventProgress,] = useAtom(BusinessEventProgress);
    const { t } = useTranslation();

    const [open, setOpen] = useState(false);

    const handleOpenChange = (state) => {
        setOpen(state);
    };

    const formActionState = MutateData(QueryId.DeleteBusinessEventTicket,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); notifySuccess(ToastId.ActionSuccessDelete); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinishState = () => {
        let properties = {};

        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;
        properties.form_field_ticketId = props.ticket.id;

        props.setFreezeActions(true);
        formActionState.mutate(properties);
    };

    return (
        <div className="flex m-2 text-white font-semibold">
            <p className="my-auto mr-3 text-sm">{props.index}. {props.ticket.name} {props.ticket.isDisabled ? <Tag className="ml-2" color="error">{t("disabled")}</Tag> : <></>}</p>
            {props.ticket.isDisabled ? <></> : <>
                <Button disabled={props.freezeActions} loading={props.freezeActions} onClick={() => { props.setTicketOverview(false); props.setGiveawayView(false); props.setTicketEditId(props.ticket.id); }} className="bg-sonar-yellow ml-2" shape="circle" icon={<EditOutlined />} />
                {businessEventProgress.isPublished ? null :
                    <Popover
                        content={<>
                            <p className="mb-4">{t("popover_ticket_delete")}</p>
                            <div>
                                <Button className="bg-sonar-green" onClick={(() => { onFinishState(); handleOpenChange(false); })}>{t("popover_confirm")}</Button>
                                <Button className="bg-red-400 ml-2" onClick={(() => { handleOpenChange(false); })}>{t("popover_cancel")}</Button>
                            </div>
                        </>}
                        title={t("popover_title_delete")}
                        trigger="click"
                        open={open}
                        onOpenChange={handleOpenChange}
                    >
                        <Button disabled={props.freezeActions} loading={props.freezeActions} onClick={() => { handleOpenChange(true); }} className="bg-sonar-red ml-2" shape="circle" icon={<DeleteOutlined />} />
                    </Popover>
                }
            </>}
        </div>
    );
}

function EditTicketType(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);
    const { t } = useTranslation();

    const ticket = props.tickets.find((x) => { return x.id === props.ticketEditId });

    const [form] = Form.useForm();

    const [sale, setSale] = useState(ticket.salePercentage);

    const [businessEventProgress, setBusinessEventProgress] = useAtom(BusinessEventProgress);

    const formActionProgress = MutateData(QueryId.SelectBusinessEventProgress,
        (data) => { setBusinessEventProgress(data?.data ? data.data : businessEventProgress); });

    const onFinishProgress = (properties) => {
        formActionProgress.mutate(properties);
    };

    const formAction = MutateData(QueryId.UpdateBusinessEventTicket,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); onFinishProgress({ BusinessId: businessId, UserId: userId, EventId: eventId }); props.setTicketOverview(true); notifySuccess(ToastId.ActionSuccessUpdate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (properties) => {
        if (!properties.form_field_ticket_date_range) {
            notifyError(ToastId.RequiredFields); return;
        };

        if (properties.form_field_ticket_name === "GIVEAWAY") {
            notifyError(ToastId.ActionWarning); return;
        }

        properties.form_field_ticket_date_range = TransformRangePickerResult(properties.form_field_ticket_date_range);

        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;
        properties.form_field_ticketId = props.ticketEditId;

        props.setFreezeActions(true);
        formAction.mutate(properties);
    };

    const { RangePicker } = DatePicker;

    return (<>
        <p className="font-semibold text-lg mb-2">{t("edit_ticket_type")}</p>
        <Form
            form={form}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinish}
        >
            <ComponentFormInput disabled={businessEventProgress.isPublished} field={"form_field_ticket_name"} placeholder={"placeholder_eventname"} value={ticket.name} feedback clear required />
            <ComponentFormTextarea disabled={businessEventProgress.isPublished} field={"form_field_ticket_description"} placeholder={t("placeholder_support")} value={ticket.description} counter clear max={999} required />
            <Form.Item name={"form_field_ticket_price"} label={t("form_field_ticket_price")} initialValue={ticket.price}>
                <InputNumber disabled addonAfter={props.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>} decimalSeparator={"."} precision={2} />
            </Form.Item>
            {ticket.price === 0 ? <></> :
                <Form.Item name={"form_field_ticket_sale"} label={<Tooltip title={t("tooltip_ticket_sale")}><span>{t("form_field_ticket_sale")}</span><QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={ticket.salePercentage} required>
                    <Slider step={5} max={95} onChange={(event) => { setSale(event); }} />
                </Form.Item>}
            <p className={"text-sonar-red mb-3" + (ticket.price === 0 ? " hidden" : Defaults.StringEmpty)}>{t("ticket_price_real")}: ({ticket.price.toFixed(2)} - {sale}%) + {props.fee.toFixed(2)} {t("sonar_fee")} = {((ticket.price - (ticket.price / 100 * sale)) + props.fee).toFixed(2)} {props.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>}</p>
            <Form.Item name={"form_field_ticket_limit"} label={t("form_field_ticket_limit")} initialValue={ticket.purchaseLimit}>
                <InputNumber disabled />
            </Form.Item>
            <Form.Item className="ant-switch-form-override" name={"form_field_ticket_bulkallowed"} label={<Tooltip title={t("tooltip_bulkpurchases")}><span>{t("form_field_ticket_bulkallowed")}</span><QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={ticket.isBulkAllowed}>
                <Switch disabled={businessEventProgress.isPublished} checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Form.Item>
            <Form.Item name={"form_field_ticket_date_range"} label={t("form_field_ticket_date_range")} initialValue={[dayjs(moment(ticket.purchaseStartDate).format(Defaults.DateFormat), Defaults.DateFormat), dayjs(moment(ticket.purchaseEndDate).format(Defaults.DateFormat), Defaults.DateFormat)]}>
                <RangePicker inputReadOnly={true} />
            </Form.Item>
            <Form.Item>
                <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_update_ticket_type")}</Button>
            </Form.Item>
        </Form>
    </>);
}

function NewTicketType(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const [free, setFree] = useState(false);
    const [price, setPrice] = useState(5.00);
    const [unlimited, setUnlimited] = useState(false);
    const [amount, setAmount] = useState(100);
    const [sale, setSale] = useState(0);
    const { t } = useTranslation();

    const { RangePicker } = DatePicker;

    const [form] = Form.useForm();

    const [businessEventProgress, setBusinessEventProgress] = useAtom(BusinessEventProgress);

    const formActionProgress = MutateData(QueryId.SelectBusinessEventProgress,
        (data) => { setBusinessEventProgress(data?.data ? data.data : businessEventProgress); });

    const onFinishProgress = (properties) => {
        formActionProgress.mutate(properties);
    };

    const formAction = MutateData(QueryId.InsertBusinessEventTicket,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); onFinishProgress({ BusinessId: businessId, UserId: userId, EventId: eventId }); props.setTicketOverview(true); notifySuccess(ToastId.ActionSuccessCreate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (properties) => {
        if (!properties.form_field_ticket_date_range) {
            notifyError(ToastId.RequiredFields); return;
        };

        if (properties.form_field_ticket_name === "GIVEAWAY") {
            notifyError(ToastId.ActionWarning); return;
        }

        properties.form_field_ticket_date_range = TransformRangePickerResult(properties.form_field_ticket_date_range);

        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;
        properties.form_field_ticket_price = free ? 0 : price;
        properties.form_field_ticket_limit = unlimited ? 999999 : amount;
        properties.form_field_ticket_sale = sale ? sale : 0;

        props.setFreezeActions(true);
        formAction.mutate(properties);
    };

    return (<>
        <p className="font-semibold text-lg mb-2">{t("new_ticket_type")}</p>
        <Form
            form={form}
            requiredMark={formTagsOptionals}
            colon={false}
            onFinish={onFinish}
        >
            <ComponentFormInput field={"form_field_ticket_name"} placeholder={"placeholder_ticketname"} feedback clear required />
            <ComponentFormTextarea field={"form_field_ticket_description"} placeholder={t("placeholder_ticketdescription")} counter clear max={999} required />
            <div className="flex">
                <Form.Item name={"form_field_ticket_price"} label={<><Tag color="error">{t("tag_onetimesave")}</Tag><span>{t("form_field_ticket_price")}</span></>} initialValue={5.00} required>
                    <InputNumber required className={free ? "hidden" : Defaults.StringEmpty} addonAfter={props.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>} changeOnWheel={false} controls={false} min={5} max={999} decimalSeparator={"."} precision={2} onChange={(event) => { if (event) { setPrice(event); return; } setPrice(0); }} />
                </Form.Item>
                <Switch checked={free} className={"ant-switch-override mt-[6px] " + (free ? Defaults.StringEmpty : "ml-2")} checkedChildren={t("free")} unCheckedChildren={t("paid")} onChange={(event => { setFree(event); })} />
            </div>
            {free ? <></> :
                <Form.Item name={"form_field_ticket_sale"} label={<><Tag color="processing">{t("tag_editable_after")}</Tag><Tooltip title={t("tooltip_ticket_sale")}><span>{t("form_field_ticket_sale")}</span><QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip></>} initialValue={0} required>
                    <Slider step={5} max={95} onChange={(event) => { setSale(event); }} />
                </Form.Item>}
            <p className={"text-sonar-yellow mb-3" + (free ? " hidden" : Defaults.StringEmpty)}>{t("ticket_price_real")}: ({price.toFixed(2)} - {sale}%) + {props.fee.toFixed(2)} {t("sonar_fee")} = {((price - (price / 100.00 * sale)) + props.fee).toFixed(2)} {props.currency === Currency.EURO ? <span>&euro;</span> : <span>&pound;</span>}</p>
            <div className="flex">
                <Form.Item name={"form_field_ticket_limit"} label={<><Tag color="error">{t("tag_onetimesave")}</Tag><span>{t("form_field_ticket_limit")}</span></>} initialValue={100} required>
                    <InputNumber required className={unlimited ? "hidden" : Defaults.StringEmpty} changeOnWheel={false} controls={false} min={1} max={99999} onChange={(event) => { setAmount(event); }} />
                </Form.Item>
                <Switch checked={unlimited} className={"ant-switch-override mt-[6px] " + (unlimited ? Defaults.StringEmpty : "ml-2")} checkedChildren={t("unlimited")} unCheckedChildren={t("limited")} onChange={(event => { setUnlimited(event); })} />
            </div>
            <Form.Item className="ant-switch-form-override" name={"form_field_ticket_bulkallowed"} label={<Tooltip title={t("tooltip_bulkpurchases")}><span>{t("form_field_ticket_bulkallowed")}</span><QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={true} required>
                <Switch checkedChildren={<CheckOutlined />} unCheckedChildren={<CloseOutlined />} />
            </Form.Item>
            <Form.Item name={"form_field_ticket_date_range"} label={<Tooltip title={t("form_field_date_range_tooltip")}>{t("form_field_ticket_date_range")}<QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip>} initialValue={[dayjs(moment().format(Defaults.DateFormat), Defaults.DateFormat), dayjs(moment().add(7, "d").format(Defaults.DateFormat), Defaults.DateFormat)]} required>
                <RangePicker required allowClear needConfirm inputReadOnly={true}
                    minDate={dayjs(moment().subtract(1, "d").format(Defaults.DateFormat), Defaults.DateFormat)}
                    maxDate={dayjs(moment().add(1, "M").format(Defaults.DateFormat), Defaults.DateFormat)}
                />
            </Form.Item>
            <Form.Item>
                <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full mt-2">{t("form_add_ticket_type")}</Button>
            </Form.Item>
        </Form>
    </>);
}

function GiveawayTicketType(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);

    const { t } = useTranslation();

    const response = GetData(QueryId.SelectBusinessEventTicketGiveawayCodes, { BusinessId: businessId, UserId: userId, EventId: eventId });

    const [form] = Form.useForm();

    const formAction = MutateData(QueryId.InsertBusinessEventTicketGiveawayCodes,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { response.refetch(); notifySuccess(ToastId.ActionSuccessCreate); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinish = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;

        props.setFreezeActions(true);
        formAction.mutate(properties);
    };

    const [searchCode, setSearchCode] = useState(Defaults.StringEmpty);

    return (<>
        <p className="font-semibold text-lg mb-2">{t("create_giveaway_codes")}</p>
        <Form
            form={form}
            requiredMark={formTagsNone}
            colon={false}
            onFinish={onFinish}
            className="mb-2"
        >
            <div className="flex">
                <Form.Item name={"form_field_amount"} label={t("form_field_amount")} initialValue={1} required>
                    <InputNumber required changeOnWheel={false} controls={false} min={1} max={10} />
                </Form.Item>
                <Form.Item>
                    <Button disabled={props.freezeActions} loading={props.freezeActions} onSubmit={(event) => { event.preventDefault(); }} onClick={() => { form.submit(); }} className="bg-sonar-blue w-full ml-2">{t("form_create_giveaway_codes")}</Button>
                </Form.Item>
            </div>
        </Form>
        <hr />
        <div className="flex">
            <p className="mb-4 mt-3 text-lg text-nowrap mr-3"><Tooltip title={t("tooltip_codes")}><span className="font-semibold">{t("giveaway_codes")}</span><QuestionCircleOutlined className="my-auto h-full ml-1" /></Tooltip></p>
            <Input onInput={(event) => { event.target.value = event.target.value.toUpperCase().replaceAll(Defaults.Space, Defaults.StringEmpty); }} allowClear className="my-auto" placeholder={t("search_code")} addonAfter={<SearchOutlined />} onChange={(event) => { setSearchCode(event.target.value); }} />
        </div>
        {response.isEmpty ? <></> :
            <Space wrap>
                {response.isLoading
                    ? <Spin />
                    : response.isFalse
                        ? <ComponentState error />
                        : response.isEmpty
                            ? <ComponentState empty />
                            : response.data.filter((x) => x.shortIndex.includes(searchCode)).sort((a, b) => { if (a.isDownloaded !== b.isDownloaded) { return a.isDownloaded ? -1 : 1; } else { return SortOnText(a.shortIndex, b.shortIndex); } }).map((code) => {
                                return <Code key={code.id} code={code} refetch={response.refetch} freezeActions={props.freezeActions} setFreezeActions={props.setFreezeActions} />
                            })}
            </Space>
        }
    </>);
}

function Code(props) {
    const [userId,] = useAtom(UserId);
    const [businessId,] = useAtom(BusinessId);
    const [eventId,] = useAtom(EventId);
    const { t } = useTranslation();

    const formActionState = MutateData(QueryId.ValidateBusinessEventTicketGiveawayCode,
        () => { props.setFreezeActions(false) }, [
        { case: QueryCase.Success, onCase: () => { props.refetch(); } },
        { case: QueryCase.Error, onCase: () => { notifyError(ToastId.ActionError) } }
    ]);

    const onFinishState = (properties) => {
        properties.form_field_userid = userId;
        properties.form_field_businessid = businessId;
        properties.form_field_eventid = eventId;
        properties.form_field_ticketId = props.code.id;

        props.setFreezeActions(true);
        formActionState.mutate(properties);
    };

    const downloadQRCode = (id) => {
        const canvas = document.getElementById(id)?.querySelector("canvas");
        if (canvas) {
            onFinishState({});

            const url = canvas.toDataURL();
            const a = document.createElement("a");
            a.download = id;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
        else {
            notifyError(ToastId.ActionError);
        }
    };

    return (<>
        <Button disabled={props.freezeActions} ghost type="text" className="h-min p-0" id={props.code.shortIndex} onClick={() => { downloadQRCode(props.code.shortIndex); }}>
            <QRCode className="w-40 h-40" errorLevel="Q" color="#1677ff" value={props.code.shortIndex} bordered={false} />
        </Button>
        <Tag className="flex py-1 m-0" color={props.code.isDownloaded ? "success" : "processing"}>
            <Paragraph className="!mb-0 copyicon-right relative w-full" copyable={{ onCopy: () => { onFinishState({}); }, tooltips: [t("copy"), t("copied")] }}>{props.code.shortIndex}</Paragraph>
        </Tag>
    </>
    );
}